import AnnouncementBar from '@algbra/ui/components/AnnouncementBar/AnnouncementBar'
import Button from '@algbra/ui/components/Button/Button'
import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import { VAlign } from '@algbra/ui/enums/align'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import '@algbra/ui/styles/globals.scss'
import { graphql, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import DialogDecoded from '../components/DialogDecoded/DialogDecoded'
import EarlyAccess from '../components/EarlyAccess/EarlyAccess'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import HeaderContainer from '../components/HeaderContainer/HeaderContainer'
import MetaTags from '../components/MetaTags/MetaTags'
import { getMetaTags } from '../utils/metaTags'
import HomeZoneRenderer from '../zones/HomeContentZone'
import styles from './home.module.scss'
import { ReactComponent as StoreQrIcon } from '../images/home/storeQR-v2.svg'
import { ReactComponent as Pay360Awards } from '../images/home/pay360-awards.svg'
import Icon from '@algbra/ui/components/Icon/Icon'
import classNames from 'classnames'

export default function LayoutHome({ pageContext, data, location }) {
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const zones = data.strapiGQL.page.contentZones
  const title = data.strapiGQL.page.title
  const subtitle = data.strapiGQL.page.subtitle

  return (
    <div>
      <MetaTags {...getMetaTags(pageContext)} />
      <HeaderContainer>
        <AnnouncementBar
          title="SC Ventures makes strategic investment in Algbra, establishes partnership with its UK sustainable finance platform Shoal"
          to="https://www.algbra.com/news/sc-ventures-makes-strategic-investment-in-algbra-establishes-partnership-with-its-uk-sustainable-finance-platform-shoal/"
          target="_blank"
        />
        <Header
          hasBorder={isMobile}
          logoUrl="/images/logo-on-pink.svg"
          hasBg={false}
          hasAnnouncement={true}
        />

        <Container
          fluid={false}
          className={styles.zIndexed}
          role="complementary"
        >
          <Row vAlign={VAlign.CENTER} className={styles.bannerArea}>
            <Column size={12} sm={6} className={styles.content}>
              <Heading level={1} type="xl" className={styles.header}>
                {title}
              </Heading>
              <FreeText
                className={classNames(styles.text, styles.subtitle)}
                innerHTML={subtitle}
              >
                {subtitle}
              </FreeText>

              <Button
                className={styles.downloadButton}
                size="normal"
                onClick={() => navigate('/download')}
              >
                Open an Algbra Account
              </Button>

              <div className={styles.downloadAppContainer}>
                <div className={styles.qrIconWrapper}>
                  <StoreQrIcon />
                </div>

                <p className={styles.qrDescription}>
                  Scan to
                  <br />
                  Download
                </p>
              </div>
              <div className={styles.awardContainer}>
                <div className={styles.pay360Wrapper}>
                  <Pay360Awards />
                </div>
                <div>
                  <p className={styles.awardTitle}>
                    Best ESG Initiative in Payments
                  </p>
                  <p className={styles.awardDescription}>Pay360 Awards</p>
                </div>
              </div>
            </Column>
            <Column size={12} sm={6} className={styles.card}>
              <StaticImage
                src="../images/home/promo-hero-5.png"
                alt="Promo background"
                layout="constrained"
                width={937}
                quality={100}
                placeholder="blurred"
                loading="eager"
                className={styles.promoHero}
              />
            </Column>
          </Row>

          <StaticImage
            src="../images/home/promo-hero-5.png"
            alt="Promo background"
            layout="constrained"
            width={937}
            quality={100}
            placeholder="blurred"
            loading="eager"
            className={styles.desktopHeroBanner}
          />
        </Container>
      </HeaderContainer>

      {zones && <HomeZoneRenderer zones={zones} location={location} />}
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        ...StrapiGQL_PagesWithContentZones
      }
    }
  }
`
