import Caption from '@algbra/ui/components/Caption/Caption'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Icon from '@algbra/ui/components/Icon/Icon'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import {
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET_BELOW,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsImage } from '@algbra/ui/resolvers'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { mapIndexed } from 'rambdax'
import React, { useState } from 'react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import 'swiper/css/effect-fade'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import { Feature } from '../../types/feature'
import styles from './FeatureRotator.module.scss'

export type ZoneFeatureRotatorProps = {
  __typename: 'StrapiGQL_ComponentZonesFeatureRotator'
  list?: Feature[]
}

SwiperCore.use([Autoplay, EffectFade])

export default function ZoneFeatureRotator(props: ZoneFeatureRotatorProps) {
  const { list } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const [activeFeatureIndex, setActiveFeatureIndex] = useState<number>(0)
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const onSlideChange = (swiper: SwiperCore) => {
    setActiveFeatureIndex(swiper.activeIndex)
  }
  return (
    <Container>
      <Whitespace type="none">
        <div className={classNames(styles.container)}>
          <span className={styles.backgroundText} role="presentation">
            Banking for Good
          </span>
          <div className={styles.content}>
            <div className={styles.rotator}>
              <div className={styles.innerShadow} />
              <Swiper
                effect="fade"
                slidesPerView={1}
                className={styles.list}
                autoplay={{ delay: 5000 }}
                onSlideChange={onSlideChange}
                fadeEffect={{ crossFade: true }}
                centeredSlides={true}
                onSwiper={swiper => setSwiperRef(swiper)}
              >
                {mapIndexed(({ image, mobileImage, title }, index) => {
                  const platformImage = isMobile ? mobileImage : image
                  return (
                    <SwiperSlide
                      className={classNames(styles.item, {
                        [styles.active]: index === activeFeatureIndex,
                      })}
                      key={platformImage.id}
                    >
                      <GatsbyImage
                        image={resolveAsImage(platformImage)}
                        alt={`Feature: ${title}`}
                        className={styles.itemImage}
                        loading="lazy"
                      />
                    </SwiperSlide>
                  )
                }, list || [])}
              </Swiper>
            </div>

            <div className={styles.checklist}>
              {mapIndexed(
                ({ title, id }, index) => (
                  <div
                    className={classNames(styles.check, {
                      [styles.checked]: index === activeFeatureIndex,
                    })}
                    key={`feature-text-${id}`}
                    onMouseEnter={() => {
                      swiperRef.slideTo(index)
                      swiperRef.autoplay.start()
                    }}
                  >
                    <Icon
                      className={styles.icon}
                      icon={getIconByName('check-circle')}
                      size={32}
                    />
                    <Caption type="md" as="span" className={styles.checkText}>
                      {title}
                    </Caption>
                  </div>
                ),
                list || []
              )}
            </div>
          </div>
        </div>
      </Whitespace>
    </Container>
  )
}
