import { Container, Row, Column } from '@algbra/ui/components/Grid/Grid'
import Icon from '@algbra/ui/components/Icon/Icon'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsAttachment, resolveAsImage } from '@algbra/ui/resolvers'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { mapIndexed } from 'rambdax'
import React from 'react'

import styles from './HomeBoxSmall.module.scss'

export type ZoneHomeBoxSmallProps = {
  __typename: 'StrapiGQL_ComponentZonesHomeBoxSmall'
  description: string
  logo: GatsbyResolvedAsset
  bgImage: GatsbyResolvedAsset
  bgImageMobile: GatsbyResolvedAsset
}

export default function ZoneHomeBoxSmall(props: ZoneHomeBoxSmallProps) {
  const { description, logo, bgImage, bgImageMobile } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)

  return (
    <Container>
      <Whitespace type="none">
        <Row>
          <Column size={12} sm={12} className={styles.column}>
            <div className={styles.container}>
              <GatsbyImage
                image={resolveAsImage(isMobile ? bgImageMobile : bgImage)}
                alt={description}
                className={styles.bgImage}
                loading="lazy"
              />
              <div className={styles.content}>
                <FreeText color="white">{description}</FreeText>
                <div className={styles.logo}>
                  <GatsbyImage
                    image={resolveAsImage(logo)}
                    alt={description}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </Column>
        </Row>
      </Whitespace>
    </Container>
  )
}
