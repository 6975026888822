import { Container, Row, Column } from '@algbra/ui/components/Grid/Grid'
import Icon from '@algbra/ui/components/Icon/Icon'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsAttachment, resolveAsImage } from '@algbra/ui/resolvers'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { mapIndexed } from 'rambdax'
import React from 'react'

import styles from './HomeBoxes.module.scss'

export type Box = {
  title: string
  description: string
  image: GatsbyResolvedAsset
  gridSize: string
  backgroundColorHEX: string
  backgroundImage: GatsbyResolvedAsset
  list: string
  imageFirst: boolean
  headingColor: string
  textColor: string
  textAlign: string
  buttonText: string
  buttonLink: string
  disclaimer: string
  disclaimerIcon: GatsbyResolvedAsset
  imageVerticalAlign: string
  logoImages: GatsbyResolvedAsset[]
  linkTheme: 'none' | 'dark' | 'white'
  textVerticalAlign: 'center'
  isLinkBeforeImage: boolean
  footerDisclaimer: string
  footerImage: GatsbyResolvedAsset
}

export type ZoneHomeBoxesProps = {
  __typename: 'StrapiGQL_ComponentZonesHomeBoxes'
  box?: Box[]
}

export default function ZoneHomeBoxes(props: ZoneHomeBoxesProps) {
  const { box } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const BoxDetailLink = props => {
    return (
      <FreeText
        className={classNames(
          [styles.boxDetailLink, props.className],
          [styles[props.theme], props.theme]
        )}
        color={props.linkColor}
      >
        <Permalink className={styles.linkText} to={props.linkUrl}>
          {props.linkText}
        </Permalink>
        <Icon
          icon={getIconByName('arrow-right')}
          size={24}
          className={styles.arrow}
        />
      </FreeText>
    )
  }

  return (
    <Container>
      <Whitespace type="none">
        <Row>
          {mapIndexed(
            (
              {
                title,
                description,
                image,
                gridSize,
                backgroundColorHEX,
                backgroundImage,
                list,
                imageFirst,
                headingColor,
                textColor,
                textAlign,
                buttonText,
                buttonLink,
                disclaimer,
                disclaimerIcon,
                imageVerticalAlign,
                logoImages,
                linkTheme,
                textVerticalAlign,
                isLinkBeforeImage,
                footerDisclaimer,
                footerImage,
              },
              index
            ) => {
              return (
                <Column
                  size={12}
                  sm={gridSize === 'full' ? 12 : 6}
                  key={`box-${index}`}
                  className={styles.column}
                >
                  <div
                    className={classNames(styles.box, styles[gridSize], {
                      [styles.imageFirst]: imageFirst,
                      [styles.hasBackgroundImage]: backgroundImage,
                    })}
                    style={{
                      backgroundColor: backgroundColorHEX,
                      backgroundImage:
                        backgroundImage &&
                        `url(${backgroundImage.resolved.publicURL})`,
                    }}
                  >
                    <Row>
                      {image && (
                        <Column
                          style={{
                            order: imageFirst ? 0 : 1,
                            alignItems: imageVerticalAlign
                              ? imageVerticalAlign
                              : 'center',
                          }}
                          size={12}
                          sm={gridSize === 'full' ? 6 : 12}
                          className={styles.imgColumn}
                        >
                          <GatsbyImage
                            image={resolveAsImage(image)}
                            alt={title}
                            className={styles.itemImage}
                            loading="lazy"
                          />
                        </Column>
                      )}

                      <Column size={12} sm={gridSize === 'full' ? 6 : 12}>
                        <div
                          className={classNames(styles.textContainer, {
                            [styles.center]: textVerticalAlign === 'center',
                            [styles.hasFooter]:
                              !!footerDisclaimer || !!footerImage,
                          })}
                          style={{
                            textAlign,
                            backgroundColor:
                              backgroundImage && isMobile && backgroundColorHEX,
                          }}
                        >
                          <div>
                            {logoImages.length > 0 && (
                              <ul className={styles.logoImagesList}>
                                {mapIndexed(
                                  (logoImage: GatsbyResolvedAsset) => (
                                    <li key={logoImage.id}>
                                      <GatsbyImage
                                        image={resolveAsImage(logoImage)}
                                        alt={title}
                                        className={styles.itemImage}
                                        loading="lazy"
                                      />
                                    </li>
                                  ),
                                  logoImages
                                )}
                              </ul>
                            )}
                            {title && (
                              <Heading
                                type="lg"
                                className={styles.title}
                                theme={
                                  headingColor && headingColor === 'white'
                                    ? 'dark'
                                    : ''
                                }
                                dangerouslySetInnerHTML={{ __html: title }}
                              />
                            )}
                            <FreeText
                              className={styles.description}
                              color={textColor || 'black'}
                            >
                              {description}
                            </FreeText>
                            {list && !isMobile && (
                              <div
                                className={styles.listing}
                                dangerouslySetInnerHTML={{ __html: list }}
                              />
                            )}
                          </div>
                          {buttonText && buttonLink && gridSize === 'full' && (
                            <BoxDetailLink
                              linkColor={textColor || 'black'}
                              linkUrl={buttonLink}
                              linkText={buttonText}
                              theme={linkTheme}
                            />
                          )}

                          {buttonText &&
                            buttonLink &&
                            gridSize === 'half' &&
                            isLinkBeforeImage && (
                              <BoxDetailLink
                                linkColor={textColor || 'black'}
                                linkUrl={buttonLink}
                                linkText={buttonText}
                                theme={linkTheme}
                                className={styles.boxDetailLinkBeforeImg}
                              />
                            )}

                          {disclaimer && (
                            <div className={styles.disclaimerContainer}>
                              {disclaimerIcon && (
                                <div
                                  className={styles.disclaimerIcon}
                                  style={{
                                    backgroundImage:
                                      disclaimerIcon &&
                                      `url(${disclaimerIcon.resolved.publicURL})`,
                                  }}
                                ></div>
                              )}

                              <FreeText
                                className={classNames(
                                  styles.disclaimer,
                                  styles[textColor]
                                )}
                              >
                                {disclaimer}
                              </FreeText>
                            </div>
                          )}

                          {(footerImage || footerDisclaimer) && (
                            <div className={styles.footer}>
                              {footerImage && (
                                <GatsbyImage
                                  image={resolveAsImage(footerImage)}
                                  alt={title}
                                  className={styles.footerImage}
                                  loading="lazy"
                                />
                              )}
                              {footerDisclaimer && (
                                <div
                                  className={styles.footerDisclaimerContainer}
                                >
                                  <FreeText
                                    className={classNames(
                                      styles.disclaimer,
                                      styles[textColor]
                                    )}
                                  >
                                    {footerDisclaimer}
                                  </FreeText>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Column>
                      {buttonText &&
                        buttonLink &&
                        gridSize === 'half' &&
                        !isLinkBeforeImage && (
                          <Column size={12} sm={12} style={{ order: 2 }}>
                            <BoxDetailLink
                              linkColor={textColor || 'black'}
                              linkUrl={buttonLink}
                              linkText={buttonText}
                              theme={linkTheme}
                              className={styles.boxDetailLinkAfterImg}
                            />
                          </Column>
                        )}
                    </Row>
                  </div>
                </Column>
              )
            },
            box || []
          )}
        </Row>
      </Whitespace>
    </Container>
  )
}
