import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Heading from '../Heading/Heading'
import styles from './Box.module.scss'

export type BoxProps = {
  color: 'primary' | 'secondary'
  className?: string
  children: ReactNode
  title?: string
  footer?: ReactNode
}
export default function Box(props: BoxProps) {
  const { color = 'primary', children, className, title, footer } = props
  return (
    <div
      className={classNames(styles.box, [styles[`color__${color}`]], className)}
    >
      {title && <Heading level={3} type="lg" className={styles.title}>{title}</Heading>}
      <div className={styles.contentArea}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  )
}
