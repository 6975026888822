import { ReactNode } from 'react';
import styles from './HeaderContainer.module.scss';

export type HeaderContainerProps = {
  children: ReactNode;
};
export default function HeaderContainer(
  props: HeaderContainerProps
): JSX.Element {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
}
