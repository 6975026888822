import Box from '@algbra/ui/components/Box/Box'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Icon from '@algbra/ui/components/Icon/Icon'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { VAlign } from '@algbra/ui/enums/align'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { map } from 'rambdax'
import { PageRef } from '../../types/footer'
import { resolveAsImage } from '@algbra/ui/resolvers'
import styles from './FeatureBoxList.module.scss'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'

export type FeatureLinked = {
  id: string
  title: string
  theme?: 'primary' | 'secondary'
  image?: GatsbyResolvedAsset
  imageMobile?: GatsbyResolvedAsset
  description?: string
  buttonText?: string
  buttonLink?: PageRef
}

export type ZoneFeatureBoxListProps = {
  __typename: 'StrapiGQL_ComponentZonesFeatureBoxList'
  list: FeatureLinked[]
}

export default function ZoneFeatureBoxList(props: ZoneFeatureBoxListProps) {
  const { list } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  return (
    <Whitespace type="none">
      <Container>
        <Row vAlign={VAlign.STRETCH}>
          {map(
            ({
              id,
              title,
              description,
              image,
              imageMobile,
              buttonLink,
              buttonText,
              theme = 'primary',
            }) => {
              const textTheme = theme === 'primary' ? 'white' : 'primary'
              const footerLink =
                buttonLink && buttonText ? (
                  <FreeText color={textTheme}>
                    <Permalink to={`/${buttonLink.slug}`}>
                      {buttonText}
                      <Icon
                        icon={getIconByName('arrow-right')}
                        size={24}
                        className={styles.arrow}
                      />
                    </Permalink>
                  </FreeText>
                ) : null
              return (
                <Column
                  size={12}
                  lg={6}
                  className={styles.itemContainer}
                  key={`feature-box-${id}`}
                >
                  <Box
                    color={theme}
                    title={title}
                    className={styles.box}
                    footer={footerLink}
                  >
                    <FreeText color={textTheme} className={styles.description}>
                      {description}
                    </FreeText>
                    {!isMobile && (
                      <GatsbyImage
                        image={resolveAsImage(image)}
                        className={styles.image}
                        alt=""
                        role="presentation"
                        loading="lazy"
                      />
                    )}
                    {isMobile && (
                      <GatsbyImage
                        image={resolveAsImage(imageMobile)}
                        alt=""
                        className={styles.mobileImage}
                        role="presentation"
                        loading="lazy"
                      />
                    )}
                  </Box>
                </Column>
              )
            },
            list || []
          )}
        </Row>
      </Container>
    </Whitespace>
  )
}
