import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import ContentBox from '../../components/ContentBox/ContentBox'
import { RichText } from '../../components/RichText/RichText'

export type ZoneFreeTextProps = {
  __typename: 'StrapiGQL_ComponentZonesFreeText'
  id: string
  body: string
  theme?: 'light' | 'dark'
}

export default function ZoneFreeText(props: ZoneFreeTextProps) {
  const { body, theme = 'light' } = props
  return (
    <Whitespace type="md">
      <ContentBox size="small">
        <RichText content={body} theme={theme} />
      </ContentBox>
    </Whitespace>
  )
}
