import { WindowLocation } from '@reach/router'
import { mapIndexed } from 'rambdax'
import ZoneFeatureBoxList, {
  ZoneFeatureBoxListProps,
} from './FeatureBoxList/FeatureBoxList'
import ZoneFeatureList, {
  ZoneFeatureListProps,
} from './FeatureList/FeatureList'
import ZoneFeatureRotator, {
  ZoneFeatureRotatorProps,
} from './FeatureRotator/FeatureRotator'
import ZoneFreeText, { ZoneFreeTextProps } from './FreeText/FreeText'
import ZonePressLogoList, {
  ZonePressLogoListProps,
} from './PressLogoList/PressLogoList'
import ZoneSpotlightHeader, {
  ZoneSpotlightHeaderProps,
} from './SpotlightHeader/SpotlightHeader'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from './InlineWaitlist/InlineWaitlist'

import ZoneHomeBoxes, { ZoneHomeBoxesProps } from './HomeBoxes/HomeBoxes'
import ZoneHomeBoxSmall, {
  ZoneHomeBoxSmallProps,
} from './HomeBoxSmall/HomeBoxSmall'

export type ZoneItemType =
  | ZoneInlineWaitlistProps
  | ZoneFreeTextProps
  | ZonePressLogoListProps
  | ZoneFeatureListProps
  | ZoneFeatureRotatorProps
  | ZoneSpotlightHeaderProps
  | ZoneFeatureBoxListProps
  | ZoneHomeBoxesProps
  | ZoneHomeBoxSmallProps

export type ZoneRendererProps = {
  zones: ZoneItemType[]
  location: WindowLocation<{ value: string }>
}

export function getComponentByZoneType(
  zone: ZoneItemType,
  key: string,
  location?: WindowLocation<{ value: string }>
) {
  switch (zone.__typename) {
    case 'StrapiGQL_ComponentZonesInlineWaitlist':
      return <ZoneInlineWaitlist key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFreeText':
      return <ZoneFreeText key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesPressLogoList':
      return <ZonePressLogoList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFeatureList':
      return <ZoneFeatureList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFeatureRotator':
      return <ZoneFeatureRotator key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesFeatureBoxList':
      return <ZoneFeatureBoxList key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesSpotlightHeader':
      return <ZoneSpotlightHeader key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesHomeBoxes':
      return <ZoneHomeBoxes key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesHomeBoxSmall':
      return <ZoneHomeBoxSmall key={key} {...zone} />
    default:
      return null
  }
}
export default function HomeZoneRenderer({
  zones,
  location,
}: ZoneRendererProps) {
  return (
    <main>
      {mapIndexed(
        (zone, index) =>
          getComponentByZoneType(zone, `${zone.__typename}-${index}`, location),
        zones
      )}
    </main>
  )
}
