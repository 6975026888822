import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { resolveAsAttachment, resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyImage } from 'gatsby-plugin-image'
import { map } from 'rambdax'
import React from 'react'
import { CarouselImage } from '../Carousel/Carousel'
import styles from './PressLogoList.module.scss'

export type ZonePressLogoListProps = {
  __typename: 'StrapiGQL_ComponentZonesPressLogoList'
  title: string
  list?: CarouselImage[]
}

export default function ZonePressLogoList(
  props: ZonePressLogoListProps
): JSX.Element {
  const { title, list = [] } = props
  return (
    <Whitespace type="md" hasAnimation={false}>
      <Container>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <Container>
              <FreeText className={styles.subtitle} type="sm">
                {title}
              </FreeText>
              <div className={styles.list}>
                {map(
                  ({ image, alternativeText }) => (
                    <span className={styles.item} key={image.id}>
                      <img
                        src={resolveAsAttachment(image)}
                        alt={`Press Logo: ${alternativeText}`}
                        loading="lazy"
                        width={image.width}
                        height={image.height}
                      />
                    </span>
                  ),
                  list || []
                )}
              </div>
            </Container>
          </div>
        </div>
      </Container>
    </Whitespace>
  )
}
