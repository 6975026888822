import classNames from 'classnames'
import ContentBox from '../ContentBox/ContentBox'
import styles from './RichText.module.scss'

export type RichTextProps = {
  content: string
  theme?: string
}
export function RichText(props: RichTextProps) {
  const { theme = "light", content } = props
  return (
    <ContentBox size="small">
      <div
        className={classNames('global-text-content', styles[`theme__${theme}`])}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </ContentBox>
  )
}
