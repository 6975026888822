import Caption from '@algbra/ui/components/Caption/Caption'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import styles from './SpotlightHeader.module.scss'

export type ZoneSpotlightHeaderProps = {
  __typename: 'StrapiGQL_ComponentZonesSpotlightHeader'
  title: string
  subtitle?: string
  titleMaxWidth?: string
}

export default function ZoneSpotlightHeader(props: ZoneSpotlightHeaderProps) {
  const { title, subtitle, titleMaxWidth } = props
  return (
    <Whitespace type="lg">
      <Container className={styles.container}>
        <Heading type="xl" style={{ maxWidth: titleMaxWidth }}>
          {title}
        </Heading>
        {subtitle && (
          <Caption type="md" className={styles.subtitle}>
            {subtitle}
          </Caption>
        )}
      </Container>
    </Whitespace>
  )
}
