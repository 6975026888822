import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { GatsbyImage } from 'gatsby-plugin-image'
import { map } from 'rambdax'
import { Feature } from '../../types/feature'
import { resolveAsImage } from '@algbra/ui/resolvers'
import styles from './FeatureList.module.scss'

export type ZoneFeatureListProps = {
  __typename: 'StrapiGQL_ComponentZonesFeatureList'
  title: string
  subtitle?: string
  list?: Feature[]
}
function ZoneFeatureList(props: ZoneFeatureListProps): JSX.Element {
  const { title, subtitle, list = [] } = props
  return (
    <Whitespace type="md">
      <Container>
        <div className={styles.header}>
          <Heading level={2} type="lg" className={styles.title}>
            {title}
          </Heading>
          <FreeText>{subtitle}</FreeText>
        </div>
        <Row>
          {map(
            ({ image, title, description, isAvailable }) => (
              <Column size={12} sm={6} key={`feature-${title}`}>
                <div className={styles.feature}>
                  <GatsbyImage
                    image={resolveAsImage(image)}
                    alt={title}
                    className={styles.image}
                    loading="lazy"
                  />
                  <div className={styles.content}>
                    {isAvailable === false && (
                      <span className={styles.availableSoon}>
                        Available Soon
                      </span>
                    )}
                    
                    <Heading level={3} type="sm" className={styles.title}>
                      {title}
                    </Heading>
                  </div>
                </div>
              </Column>
            ),
            list || []
          )}
        </Row>
      </Container>
    </Whitespace>
  )
}

export default ZoneFeatureList
